<template>
  <div>
    <!-- banner start -->
    <section class="banner_sec return_to_office">
      <div class="banner_hed">
        <h1 class="heading_2">The Future of Work</h1>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec return_office_text">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box">
              <h3 class="heading_3">
                Returning to the office? Staying Remote? Shifting to Hybrid?
                Here are some resources for you.
              </h3>
            </div>
          </div>
          <div class="col-md-12">
            <div class="learn_more_btn text-center upMar">
              <button
                v-scroll-to="{
                  el: '#why-choose1',
                  easing: 'linear',
                  offset: -250,
                }"
                class="no-border"
              >
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- return card start -->
    <section class="return_card_sec py_60" id="why-choose1">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-md-12 col-lg-4 col-xl-4 d-flex justify-content-center"
          >
            <div class="return_card_box">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#submit_form_and_download"
                @click="openForm('wfh')"
              >
                <img
                  src="../../assets/home/images/return_to_office/ergo.png"
                  alt="img"
                />
                <h6 class="heading_6">
                  Working From <br />
                  Home
                </h6>
                <p>
                  <b>Download this ebook</b> to make working from home a
                  success!
                </p>
              </a>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center">
            <div class="return_card_box">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#submit_form_and_download"
                @click="openForm('return_to_office_checklist')"
              >
                <img
                  src="../../assets/home/images/return_to_office/r2.png"
                  alt="img"
                />
                <h6 class="heading_6">
                  Return To Office <br />
                  Ergo Checklist
                </h6>
                <p>
                  <b>Download this checklist</b> to make sure you are ready!
                </p>
              </a>
            </div>
          </div>

          <div class="col-md-6 col-lg-4 col-xl-4 d-flex justify-content-center">
            <div class="return_card_box">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#submit_form_and_download"
                @click="openForm('hybrid_essentials')"
              >
                <img
                  src="../../assets/home/images/return_to_office/r3.png"
                  alt="img"
                />
                <h6 class="heading_6">
                  Hybrid <br />
                  Essentials
                </h6>
                <p><b>Download this ebook</b> to navigate hybrid working.</p>
              </a>
            </div>
          </div>
        </div>
      </div> 
    </section>
    <!-- return card end -->

    <!-- not sure start -->
    <section class="not_sure_sec py_60 bg_grey">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="not_sure_box text-center">
              <h3 class="heading_3">
                Not sure what you need? <br />Let us help!
              </h3>

              <router-link :to="{ name: 'learn_more' }" class="btn btn_primary"
                >Let’s Talk</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- not sure end -->
    <div
      class="modal fade meet_our_team_modal"
      id="submit_form_and_download"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header border-bottom-0">
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form
              class="mt_10 captureData"
              method="post"
              @submit.prevent="downloadForm"
              data-vv-scope="downloadForm"
            >
              <div class="mb-3 form-group position-relative">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Name"
                  v-model="full_name"
                  v-validate="'required'"
                  name="full_name"
                  data-vv-as="Name"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.full_name')"
                >
                  {{ errors.first("downloadForm.full_name") }}
                </small>
              </div>
              <div class="mb-3 form-group">
                <input
                  type="email"
                  class="form-control"
                  v-model="email"
                  aria-describedby="emailHelp"
                  placeholder="Work Email"
                  v-validate="'required|email'"
                  name="email"
                  data-vv-as="Email"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.email')"
                >
                  {{ errors.first("downloadForm.email") }}
                </small>
              </div>

              <div class="mb-3 form-group">
                <input
                  type="text"
                  class="form-control"
                  aria-describedby="emailHelp"
                  placeholder="Company"
                  v-validate="'required'"
                  v-model="company_name"
                  name="company"
                  data-vv-as="Company"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.company')"
                >
                  {{ errors.first("downloadForm.company") }}
                </small>
              </div>
              <div class="form-group mb_50">
                <v-select
                  v-model="manage_program"
                  class=""
                  :options="['Yes', 'No']"
                  placeholder="Do you manage a program?"
                  v-validate="'required'"
                  data-vv-as="Manage Program"
                  name="manage_program"
                />
                <small
                  class="error text-danger"
                  v-show="errors.has('downloadForm.manage_program')"
                >
                  {{ errors.first("downloadForm.manage_program") }}
                </small>
              </div>
              <div class="text-center mt_30">
                <button class="btn btn_primary" type="submit">Download</button>
                <!-- <a class="btn btn_primary" type="submit" href="assets/home/pdf/Ergo_Tips_for_Kids.pdf" download>
                   Download
                </a> -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import store from "@/store/index";
import "vue-select/dist/vue-select.css";

export default {
  name: "return_to_office",
  data() {
    return {
      pdf_for: null,
      full_name: null,
      email: null,
      company_name: null,
      manage_program: null,
    };
  },
  mounted() { 
    store.dispatch("Home/getHomeData").then(() => {});
  },
  methods: {
    testfunct(){ 
        var url = 'https://staging.pbergo.com/blogs/download-pdfs/wfh.pdf';
        this.download_file(url,'test.html');
    },
    /* Helper function */
    download_file(fileURL, fileName) {
        // for non-IE
        if (!window.ActiveXObject) {
            var save = document.createElement('a');
            save.href = fileURL;
            save.target = '_blank';
            var filename = fileURL.substring(fileURL.lastIndexOf('/')+1);
            save.download = fileName || filename;
            if ( navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search("Chrome") < 0) {
            document.location = save.href; 
          // window event not working here
          }else{
                var evt = new MouseEvent('click', {
                    'view': window,
                    'bubbles': true,
                    'cancelable': false
                });
                save.dispatchEvent(evt);
                (window.URL || window.webkitURL).revokeObjectURL(save.href);
          }	
        }
        // for IE < 11
        else if ( !! window.ActiveXObject && document.execCommand)     {
            var _window = window.open(fileURL, '_blank');
            _window.document.close();
            _window.document.execCommand('SaveAs', true, fileName || fileURL)
            _window.close();
        }
    },
    openForm(pdf_for) { 
      this.$validator.reset();
      this.full_name =  null;
      this.email =  null;
      this.company_name =  null;
      this.manage_program =  null;
      this.pdf_for = pdf_for;
    },
    downloadForm() {      
      this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      this.$validator.validateAll("downloadForm").then((isValid) => {
        if (isValid) {
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/submit-leads",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem(
                "userToken"
              )}`,
            },
            data: {
              pdf_for: this.pdf_for,
              full_name: this.full_name,
              email: this.email,
              company_name: this.company_name,
              manage_program: this.manage_program,
            },
          };
          this.axios(config)
            .then(({ data }) => {
              // console.log(data);
              // this.download_file(data.pdf_url,this.pdf_for+".pdf");
               var fileURL = data.pdf_url;
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.target = '_blank';
              fileLink.setAttribute("download", this.pdf_for+".pdf");
              document.body.appendChild(fileLink);
              fileLink.click();

              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              this.$toast.success("PDF downloaded successfully", {
                position: "top-right",
              });
              document.getElementsByClassName('btn-close')[0].click();
            })
            .catch(({ response }) => {
              this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              //if(response.status==500){
                document.getElementsByClassName('btn-close')[0].click();
                this.$toast.error(response.data.error, {
                  position: "top-right",
                });
              // }
              /*this.$toast.error(response.data.error.email[0], {
                position: "top-right",
              });*/
            });
        } else {
          this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          console.log("Error In Validation", this.errors);
          //   this.$parent.isLoader = false;
        }
      });
    },
  },
};
</script>